<template>
  <div class="system-config-smtp-form">
    <a-spin :spinning="loading" :tip="labels.form.loading">
      <ValidationObserver ref="observer">
        <a-form
          :model="variables"
          :label-width="80"
          slot-scope="{ validate }"
          @submit.prevent="validate().then(handleSubmit)"
        >
          <a-row type="flex" justify="start" :gutter="30">
            <a-col :span="24">
              <a-card>
                <a-row type="flex" justify="end" :gutter="24">
                  <a-col>
                    <a-button
                      type="primary"
                      :loading="submitting"
                      @click="validate().then(handleSubmit)"
                      class="btn-submit"
                    >
                      <span>{{ labels.form.save }}</span>
                    </a-button>
                  </a-col>
                  <a-col :span="24">
                    <a-divider />
                  </a-col>
                </a-row>

                <a-row
                  v-for="(variable, key) in variables"
                  :key="key"
                  type="flex"
                  justify="space-between"
                  :gutter="24"
                  class="border-bottom"
                >
                  <a-col :xs="24" :sm="24" :md="8">
                    <div
                      :style="{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                      }"
                    >
                      <Input
                        v-if="editingLabel === variable.description"
                        v-model="variable.description"
                        :label="labels.form.description.label"
                        :placeholder="labels.form.description.placeholder"
                        :showLabel="false"
                        class="mx-1"
                        :style="{
                          width: '100%',
                          textAlign: 'left',
                          marginTop: '0',
                        }"
                      />
                      <Input
                        v-else
                        v-model="variable.label"
                        :title="variable.label"
                        :label="labels.form.name.label"
                        :readOnly="true"
                        :disabled="true"
                        class="custom-input mx-1"
                        :showLabel="false"
                        :style="{
                          width: '100%',
                          textAlign: 'left',
                          marginTop: '0',
                        }"
                      />
                      <a-button
                        v-if="editingLabel === variable.description"
                        type="link"
                        :loading="submitting"
                        @click="editingLabel = null"
                        icon="check-circle"
                        :title="labels.form.description.save"
                      />
                      <a-button
                        v-else
                        type="link"
                        :loading="submitting"
                        @click="editingLabel = variable.description"
                        icon="edit"
                        :title="labels.form.description.edit"
                      />
                    </div>
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="16">
                    <Input
                      v-if="
                        ['number', 'text', 'textarea'].includes(variable.type)
                      "
                      :type="variable.type"
                      v-model="variable.value"
                      :label="labels.form.value.label"
                      :placeholder="labels.form.value.placeholder"
                      v-bind="
                        variable.type === 'number' ? { precision: 2 } : {}
                      "
                      :showLabel="false"
                    />
                    <SelectTagsField
                      v-else-if="variable.type === 'tag'"
                      v-model="variable.value"
                      :label="labels.form.value.label"
                      :placeholder="labels.form.value.placeholder"
                      :showLabel="false"
                      v-bind="
                        variable.name.includes('email')
                          ? { validate: validateEmail }
                          : {}
                      "
                    />
                  </a-col>
                </a-row>
              </a-card>
            </a-col>
          </a-row>
        </a-form>
      </ValidationObserver>
    </a-spin>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import labels from "@/utils/labels";
import { validateEmail } from "@/utils/utils";
import Input from "../../../components/core/VeeValidateForm/Input.vue";
import SelectTagsField from "../../../components/core/VeeValidateForm/SelectTagsField.vue";
import { mapActions } from "vuex";
import { isArray } from "lodash";

export default {
  name: "CraueConfigForm",
  components: {
    ValidationObserver,
    Input,
    SelectTagsField,
  },
  data() {
    return {
      labels: labels.systemConfig.tabs.craueConfig,
      exception: labels.exception,
      loading: false,
      submitting: false,
      variables: [],
      validateEmail,
      editingLabel: null,
    };
  },
  async created() {
    this.resetForm();
    try {
      this.loading = true;
      const { data = [] } = await this.fetchCraueConfig();
      this.variables = data;
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions("systemConfig", ["fetchCraueConfig", "saveCraueConfig"]),
    async handleSubmit() {
      if (this.submitting) return;
      const { variables, saveCraueConfig } = this;
      try {
        this.submitting = true;
        const { data, message } = await saveCraueConfig({
          variables: variables.map(({ value, ...rest }) => ({
            ...rest,
            value: isArray(value) ? JSON.stringify(value) : value?.toString(),
          })),
        });
        this.variables = data;
        this.$message.success(message);
      } catch (error) {
        this.$message.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error.message
        );
        this.$log.error(error);
      } finally {
        this.submitting = false;
      }
    },
    resetForm() {
      this.variables = [];
    },
    async onlyAlfaNumeric(value = "", field, position = 0) {
      this.variables[position][field] = await value
        ?.toString()
        ?.replace(/[^a-zA-Z0-9.-]/g, "")
        ?.toLowerCase();
    },
  },
  beforeDestroy() {
    this.variables = [];
  },
};
</script>
<style lang="scss">
@import "../../../assets/scss/variable";
.system-config-smtp-form {
  .btn-submit {
    text-align: center !important;
  }
  .border-bottom {
    border-bottom: 1.5px solid color(border-color);
    &:last-child {
      border-bottom: none;
    }
  }
  .custom-input {
    .ant-input[disabled] {
      background-color: color(--white) !important;
      border-color: color(--white) !important;
      color: #000 !important;
      font-weight: 700 !important;
      font-size: 16px;
    }
  }
  .ant-form-item {
    margin-bottom: 5px !important;
    margin-top: 5px !important;
  }
}
</style>
