<template>
  <ValidationProvider :vid="vid" :name="$attrs.label" :rules="rules">
    <a-form-item
      slot-scope="{ errors, flags }"
      :label="showLabel ? $attrs.label : ''"
      :align="$attrs.align || 'left'"
      :validateStatus="resolveState({ errors, flags })"
      :help="errors[0]"
      :htmlFor="$attrs.htmlFor"
      :labelCol="$attrs.labelCol"
    >
      <a-select
        v-bind="$attrs"
        :style="{ width: '100% !important', ...($attrs.customStyle || {}) }"
        :value="innerValue"
        mode="tags"
        @change="handleChange"
      />
    </a-form-item>
  </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
export default {
  name: "SelectTagsField",
  components: {
    ValidationProvider,
  },
  emits: ["blur", "change", "focus", "input"],
  props: {
    vid: {
      type: String,
    },
    value: {
      type: null,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    uppercase: {
      type: Boolean,
      default: false,
    },
    lowercase: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [Object, String, Array],
      default: "",
    },
  },
  data: () => ({
    innerValue: [],
  }),
  methods: {
    resolveState({ errors, flags }) {
      if (errors[0]) {
        return "error";
      }

      if (flags.pending) {
        return "validating";
      }

      if (flags.valid) {
        return "success";
      }

      return "";
    },
    handleChange(value) {
      let data =
        this.$attrs.validate && typeof this.$attrs.validate === "function"
          ? this.$attrs.validate(value)
          : value;

      if (this.uppercase) {
        data = data.map((item) => item.toUpperCase());
      }
      if (this.lowercase) {
        data = data.map((item) => item.toLowerCase());
      }
      this.innerValue = data;
      this.$emit("update:modelValue", data);
      this.$emit("change", data);
    },
  },
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value: {
      handler(newVal) {
        this.innerValue = newVal;
      },
      immediate: true,
    },
  },
};
</script>
