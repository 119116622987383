var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"system-config-smtp-form"},[_c('a-spin',{attrs:{"spinning":_vm.loading,"tip":_vm.labels.form.loading}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ validate }){return _c('a-form',{attrs:{"model":_vm.variables,"label-width":80},on:{"submit":function($event){$event.preventDefault();validate().then(_vm.handleSubmit)}}},[_c('a-row',{attrs:{"type":"flex","justify":"start","gutter":30}},[_c('a-col',{attrs:{"span":24}},[_c('a-card',[_c('a-row',{attrs:{"type":"flex","justify":"end","gutter":24}},[_c('a-col',[_c('a-button',{staticClass:"btn-submit",attrs:{"type":"primary","loading":_vm.submitting},on:{"click":function($event){validate().then(_vm.handleSubmit)}}},[_c('span',[_vm._v(_vm._s(_vm.labels.form.save))])])],1),_c('a-col',{attrs:{"span":24}},[_c('a-divider')],1)],1),_vm._l((_vm.variables),function(variable,key){return _c('a-row',{key:key,staticClass:"border-bottom",attrs:{"type":"flex","justify":"space-between","gutter":24}},[_c('a-col',{attrs:{"xs":24,"sm":24,"md":8}},[_c('div',{style:({
                      display: 'flex',
                      width: '100%',
                      alignItems: 'center',
                    })},[(_vm.editingLabel === variable.description)?_c('Input',{staticClass:"mx-1",style:({
                        width: '100%',
                        textAlign: 'left',
                        marginTop: '0',
                      }),attrs:{"label":_vm.labels.form.description.label,"placeholder":_vm.labels.form.description.placeholder,"showLabel":false},model:{value:(variable.description),callback:function ($$v) {_vm.$set(variable, "description", $$v)},expression:"variable.description"}}):_c('Input',{staticClass:"custom-input mx-1",style:({
                        width: '100%',
                        textAlign: 'left',
                        marginTop: '0',
                      }),attrs:{"title":variable.label,"label":_vm.labels.form.name.label,"readOnly":true,"disabled":true,"showLabel":false},model:{value:(variable.label),callback:function ($$v) {_vm.$set(variable, "label", $$v)},expression:"variable.label"}}),(_vm.editingLabel === variable.description)?_c('a-button',{attrs:{"type":"link","loading":_vm.submitting,"icon":"check-circle","title":_vm.labels.form.description.save},on:{"click":function($event){_vm.editingLabel = null}}}):_c('a-button',{attrs:{"type":"link","loading":_vm.submitting,"icon":"edit","title":_vm.labels.form.description.edit},on:{"click":function($event){_vm.editingLabel = variable.description}}})],1)]),_c('a-col',{attrs:{"xs":24,"sm":24,"md":16}},[(
                      ['number', 'text', 'textarea'].includes(variable.type)
                    )?_c('Input',_vm._b({attrs:{"type":variable.type,"label":_vm.labels.form.value.label,"placeholder":_vm.labels.form.value.placeholder,"showLabel":false},model:{value:(variable.value),callback:function ($$v) {_vm.$set(variable, "value", $$v)},expression:"variable.value"}},'Input',
                      variable.type === 'number' ? { precision: 2 } : {}
                    ,false)):(variable.type === 'tag')?_c('SelectTagsField',_vm._b({attrs:{"label":_vm.labels.form.value.label,"placeholder":_vm.labels.form.value.placeholder,"showLabel":false},model:{value:(variable.value),callback:function ($$v) {_vm.$set(variable, "value", $$v)},expression:"variable.value"}},'SelectTagsField',
                      variable.name.includes('email')
                        ? { validate: _vm.validateEmail }
                        : {}
                    ,false)):_vm._e()],1)],1)})],2)],1)],1)],1)}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }