<template>
  <div class="deposit-accounts-form">
    <a-spin :spinning="loading" :tip="labels.form.loading">
      <ValidationObserver ref="observer">
        <a-form
          :model="variable"
          :label-width="80"
          slot-scope="{ validate }"
          @submit.prevent="validate().then(handleSubmit)"
        >
          <a-row type="flex" justify="start" :gutter="30">
            <a-col :span="24">
              <a-card>
                <a-row type="flex" justify="end" :gutter="24">
                  <a-col>
                    <a-button
                      type="primary"
                      icon="plus"
                      :loading="loading"
                      @click="open = true"
                      class="btn-add"
                      ghost
                    >
                      <span>{{ labels.form.add }}</span>
                    </a-button>
                    <a-divider type="vertical" />
                    <a-button
                      type="primary"
                      :loading="submitting"
                      @click="validate().then(handleSubmit)"
                      class="btn-submit"
                    >
                      <span>{{ labels.form.save }}</span>
                    </a-button>
                  </a-col>
                  <a-col :span="24">
                    <a-divider />
                  </a-col>
                </a-row>

                <a-row
                  type="flex"
                  justify="space-between"
                  :gutter="24"
                  class="border-bottom"
                >
                  <a-col :span="24">
                    <div
                      class="deposit-account-list-container"
                      v-if="variable?.value?.length"
                    >
                      <div
                        class="deposit-account-list-item"
                        v-for="(account, key) in variable.value"
                        :key="key"
                      >
                        <div class="deposit-account-list-item-controls">
                          <a-button
                            type="link"
                            icon="edit"
                            size="small"
                            class="edit-btn"
                            @click="handleEdit({ text: account, index: key })"
                            :title="labels.card.actions.edit"
                          />
                          <a-button
                            type="link"
                            icon="delete"
                            size="small"
                            class="delete-btn"
                            @click="handleDelete({ text: account, index: key })"
                            style="color: red"
                            :title="labels.card.actions.delete"
                          />
                        </div>
                        <span v-html="account" />
                      </div>
                    </div>
                    <a-empty
                      v-else
                      :description="labels.card.emptyText"
                      :image="Empty.PRESENTED_IMAGE_SIMPLE"
                    />
                  </a-col>
                </a-row>
              </a-card>
            </a-col>
          </a-row>
        </a-form>
      </ValidationObserver>
    </a-spin>
    <a-modal :visible="open" :closable="false" :width="isMobile ? '100%' : 600">
      <template #title>
        <a-icon type="plus" />
        {{ labels.form.addModalTitle }}
      </template>
      <ValidationObserver ref="formObserver">
        <a-form
          :model="form"
          :label-width="80"
          slot-scope="{ validate }"
          @submit.prevent="validate().then(handleAdd)"
        >
          <a-row type="flex" justify="start">
            <a-col :span="24">
              <a-form-item :label="labels.form.account.label">
                <wysiwyg v-model="form.text" :minHeight="600" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </ValidationObserver>
      <template #footer>
        <a-button type="danger" @click="handleCancel">{{
          labels.form.cancel
        }}</a-button>
        <a-button type="primary" @click="handleAdd">
          {{ labels.form.add }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import labels from "@/utils/labels";
import { mapActions, mapState } from "vuex";
import * as _ from "lodash";
import { Empty } from "ant-design-vue";

const defaultVariable = {
  name: "deposit_account_list",
  value: [],
};

const defaultForm = {
  index: undefined,
  text: "",
};

export default {
  name: "DepositAccountsForm",
  components: {
    ValidationObserver,
  },
  data() {
    return {
      labels: labels.systemConfig.tabs.depositAccounts,
      exception: labels.exception,
      loading: false,
      submitting: false,
      variable: _.cloneDeep(defaultVariable),
      open: false,
      form: _.cloneDeep(defaultForm),
      Empty,
    };
  },
  async created() {
    this.resetForm();
    this.handleFetchDepositAccounts();
  },
  computed: {
    ...mapState("layout", ["screenWidth"]),
    isMobile() {
      return this.screenWidth < 600;
    },
  },
  methods: {
    ...mapActions("systemConfig", ["fetchDepositAccounts", "saveCraueConfig"]),
    async handleFetchDepositAccounts() {
      try {
        this.loading = true;
        const { data } = await this.fetchDepositAccounts();
        Object.assign(this.variable, {
          ...data,
          value: JSON.parse(data.value),
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async handleSubmit() {
      const { variable, saveCraueConfig } = this;
      if (this.submitting) return;
      try {
        this.submitting = true;
        const { message } = await saveCraueConfig({
          variables: [
            {
              ...variable,
              value: JSON.stringify(variable.value),
            },
          ],
        });
        await this.handleFetchDepositAccounts();
        this.$message.success(message);
      } catch (error) {
        this.$message.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error.message
        );
        this.$log.error(error);
      } finally {
        this.submitting = false;
      }
    },
    resetForm() {
      this.form = _.cloneDeep(defaultForm);
      if (this.$refs.formObserver) this.$refs.formObserver.reset();
    },
    async handleAdd() {
      try {
        const isValid = await this.$refs.formObserver.validate();
        if (!isValid) return;

        const { form, variable } = this;
        const { text, index = null } = form;
        const { value } = variable;
        if (index !== null) {
          value.splice(index, 1, text);
        } else {
          value.push(text);
        }
        this.open = false;
        this.resetForm();
      } catch (error) {
        console.error(error);
      }
    },
    handleDelete(record) {
      const { value } = this.variable;
      value.splice(record.index, 1);
    },
    handleCancel() {
      this.open = false;
      this.resetForm();
    },
    handleEdit(record) {
      this.form = _.cloneDeep(record);
      this.open = true;
    },
  },
  beforeDestroy() {
    this.form = _.cloneDeep(defaultForm);
    this.variable = _.cloneDeep(defaultVariable);
  },
};
</script>
<style lang="scss">
@import "../../../assets/scss/variable";
.deposit-accounts-form {
  .btn-submit {
    text-align: center !important;
  }
  .border-bottom {
    border-bottom: 1.5px solid color(border-color);
    &:last-child {
      border-bottom: none;
    }
  }
  .deposit-account-list-container {
    min-width: 100%;
    max-width: 100%;
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    flex-wrap: wrap;
    .deposit-account-list-item {
      display: inline-block;
      width: 240px;
      padding: 12px 15px;
      border: 1px solid color(border-color);
      box-shadow: 0 2px 4px 0 color(border-color);
      border-radius: 5px;
      position: relative;
      &-controls {
        text-align: right;
        position: absolute;
        top: 8px;
        right: 8px;
        .edit-btn {
          color: color(primary);
          font-size: 16px;
          margin-right: 10px;
        }
        .delete-btn {
          color: color(error);
          font-size: 16px;
        }
      }
    }
  }
}
</style>
