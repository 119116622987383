<template>
  <div class="system-config-smtp-form">
    <a-spin :spinning="loading" :tip="labels.form.loading">
      <ValidationObserver ref="observer">
        <a-form
          :model="form"
          :label-width="80"
          slot-scope="{ validate }"
          @submit.prevent="validate().then(handleSubmit)"
        >
          <a-row type="flex" justify="start" :gutter="30">
            <a-col :xs="24" :sm="24" :md="12">
              <a-card>
                <a-row type="flex" justify="space-between" :gutter="24">
                  <a-col :xs="24" :sm="24" :md="12">
                    <Input
                      v-model="form.host"
                      :label="labels.form.host.label"
                      rules="required"
                      :placeholder="labels.form.host.placeholder"
                    />
                  </a-col>
                </a-row>
                <a-row type="flex" justify="space-between" :gutter="24">
                  <a-col :xs="24" :sm="24" :md="12">
                    <Input
                      v-model="form.port"
                      :label="labels.form.port.label"
                      :placeholder="labels.form.port.placeholder"
                      @input="onlyNumber($event, 'port')"
                      rules="required"
                    />
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="12">
                    <Input
                      v-model="form.encryption"
                      :label="labels.form.encryption.label"
                      rules="required"
                      :placeholder="labels.form.encryption.placeholder"
                      :maxLength="3"
                    />
                  </a-col>
                </a-row>
                <a-row type="flex" justify="space-between" :gutter="24">
                  <a-col :xs="24" :sm="24" :md="12">
                    <Input
                      v-model="form.user"
                      :label="labels.form.user.label"
                      rules="required|email"
                      :placeholder="labels.form.user.placeholder"
                    />
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="12">
                    <Input
                      v-model="form.password"
                      type="password"
                      :label="labels.form.password.label"
                      :rules="'required'"
                      :placeholder="labels.form.password.placeholder"
                    />
                  </a-col>
                </a-row>
                <a-row type="flex" justify="end" :gutter="24">
                  <a-col>
                    <a-button
                      type="primary"
                      :loading="submitting"
                      @click="validate().then(handleSubmit)"
                      class="btn-submit"
                    >
                      <span>{{ labels.form.save }}</span>
                    </a-button>
                  </a-col>
                </a-row>
              </a-card>
            </a-col>
          </a-row>
        </a-form>
      </ValidationObserver>
    </a-spin>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import labels from "@/utils/labels";
import Input from "../../../components/core/VeeValidateForm/Input.vue";
import { mapActions } from "vuex";
import _ from "lodash";

const initialFormState = {
  host: "",
  port: "",
  encryption: "",
  user: "",
  password: "",
};

export default {
  name: "SmtpForm",
  components: {
    ValidationObserver,
    Input,
  },
  data() {
    return {
      labels: labels.systemConfig.tabs.smtpConfig,
      exception: labels.exception,
      loading: false,
      submitting: false,
      form: _.cloneDeep(initialFormState),
    };
  },
  async created() {
    this.resetForm();
    try {
      this.loading = true;
      const { data = {} } = await this.fetchSmptpConfig();
      Object.assign(this.form, data);
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions("systemConfig", ["fetchSmptpConfig", "saveSmptpConfig"]),
    async handleSubmit() {
      const { form, saveSmptpConfig } = this;
      if (this.submitting) return;
      try {
        this.submitting = true;
        const { data, message } = await saveSmptpConfig(form);
        Object.assign(this.form, data);
        this.$message.success(message);
      } catch (error) {
        this.$message.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error.message
        );
        this.$log.error(error);
      } finally {
        this.submitting = false;
      }
    },
    resetForm() {
      this.form = _.cloneDeep(initialFormState);
    },
    async onlyNumber(value = "", field) {
      this.form[field] = await value?.toString()?.replace(/[^0-9]/g, "");
    },
  },
  beforeDestroy() {
    this.form = _.cloneDeep(initialFormState);
  },
};
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/variable";
.btn-submit {
  text-align: center !important;
}
</style>
