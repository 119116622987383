<template>
  <div class="welcome-page-form">
    <a-spin :spinning="loading" :tip="labels.form.loading">
      <ValidationObserver ref="observer">
        <a-form
          :model="variable"
          :label-width="80"
          slot-scope="{ validate }"
          @submit.prevent="validate().then(handleSubmit)"
        >
          <a-row type="flex" justify="start" :gutter="30">
            <a-col :span="24">
              <a-card>
                <a-row type="flex" justify="end" :gutter="24">
                  <a-col>
                    <a-button
                      type="primary"
                      :loading="submitting"
                      @click="validate().then(handleSubmit)"
                      class="btn-submit"
                    >
                      <span>{{ labels.form.save }}</span>
                    </a-button>
                  </a-col>
                  <a-col :span="24">
                    <a-divider />
                  </a-col>
                </a-row>

                <a-row
                  type="flex"
                  justify="space-between"
                  :gutter="24"
                  class="border-bottom"
                >
                  <a-col :span="24" v-if="variable">
                    <a-form-item :label="variable.label">
                      <wysiwyg v-model="variable.value" :minHeight="800" />
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-card>
            </a-col>
          </a-row>
        </a-form>
      </ValidationObserver>
    </a-spin>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import labels from "@/utils/labels";
import { mapActions } from "vuex";

export default {
  name: "WelcomePageForm",
  components: {
    ValidationObserver,
  },
  data() {
    return {
      labels: labels.systemConfig.tabs.welcomePage,
      exception: labels.exception,
      loading: false,
      submitting: false,
      variable: null,
    };
  },
  async created() {
    this.resetForm();
    this.handleFetchWelcomePage();
  },
  methods: {
    ...mapActions("systemConfig", ["fetchWelcomePage", "saveCraueConfig"]),
    async handleFetchWelcomePage() {
      try {
        this.loading = true;
        const { data } = await this.fetchWelcomePage();
        this.variable = data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async handleSubmit() {
      const { variable, saveCraueConfig } = this;
      if (this.submitting) return;
      try {
        this.submitting = true;
        const { message } = await saveCraueConfig({ variables: [variable] });
        await this.handleFetchWelcomePage();
        this.$message.success(message);
      } catch (error) {
        this.$message.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error.message
        );
        this.$log.error(error);
      } finally {
        this.submitting = false;
      }
    },
    resetForm() {
      this.variable = null;
    },
  },
  beforeDestroy() {
    this.variable = null;
  },
};
</script>
<style lang="scss">
@import "../../../assets/scss/variable";
.welcome-page-form {
  .btn-submit {
    text-align: center !important;
  }
  .border-bottom {
    border-bottom: 1.5px solid color(border-color);
    &:last-child {
      border-bottom: none;
    }
  }
}
</style>
