<template>
  <div class="credit-card-payment-clients-form">
    <a-spin :spinning="loading" :tip="labels.form.loading">
      <ValidationObserver ref="observer">
        <a-form
          :model="variable"
          :label-width="80"
          slot-scope="{ validate }"
          @submit.prevent="validate().then(handleSubmit)"
        >
          <a-row type="flex" justify="start" :gutter="30">
            <a-col :span="24">
              <a-card>
                <a-row type="flex" justify="end" :gutter="24">
                  <a-col>
                    <a-button
                      type="primary"
                      icon="plus"
                      :loading="loading"
                      @click="open = true"
                      class="btn-add"
                      ghost
                    >
                      <span>{{ labels.form.add }}</span>
                    </a-button>
                    <a-divider type="vertical" />
                    <a-button
                      type="primary"
                      :loading="submitting"
                      @click="validate().then(handleSubmit)"
                      class="btn-submit"
                    >
                      <span>{{ labels.form.save }}</span>
                    </a-button>
                  </a-col>
                  <a-col :span="24">
                    <a-divider />
                  </a-col>
                </a-row>

                <a-row
                  type="flex"
                  justify="space-between"
                  :gutter="24"
                  class="border-bottom"
                >
                  <a-col :span="24">
                    <StandardTable
                      :data="variable?.value || []"
                      :columns="columns"
                      :loading="loading"
                      :paginate="false"
                      :rowKey="'url'"
                      :scroll="{ x: 1000 }"
                      size="small"
                      :locale="{ emptyText: labels.table.emptyText }"
                    />
                  </a-col>
                </a-row>
              </a-card>
            </a-col>
          </a-row>
        </a-form>
      </ValidationObserver>
    </a-spin>
    <a-modal :visible="open" :closable="false">
      <template #title>
        <a-icon type="plus" />
        {{ labels.form.addModalTitle }}
      </template>
      <ValidationObserver ref="formObserver">
        <a-form
          :model="form"
          :label-width="80"
          slot-scope="{ validate }"
          @submit.prevent="validate().then(handleAdd)"
        >
          <a-row type="flex" justify="start" :gutter="30">
            <a-col :xs="24" :sm="24" :md="12" :lg="12">
              <Input
                type="number"
                :min="0"
                :max="100"
                :label="labels.form.percentage.label"
                :placeholder="labels.form.percentage.placeholder"
                v-model="form.percentage"
                rules="required|numeric"
              />
            </a-col>
          </a-row>
          <a-row type="flex" justify="start" :gutter="30">
            <a-col :span="24">
              <TextArea
                :label="labels.form.url.label"
                :placeholder="labels.form.url.placeholder"
                v-model="form.url"
                rules="required|url: { require_protocol: true }"
              />
            </a-col>
          </a-row>
        </a-form>
      </ValidationObserver>
      <template #footer>
        <a-button type="danger" @click="handleCancel">{{
          labels.form.cancel
        }}</a-button>
        <a-button type="primary" @click="handleAdd">
          {{ labels.form.add }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import labels from "@/utils/labels";
import { mapActions } from "vuex";
import StandardTable from "../../../components/core/table/StandardTable.vue";
import Input from "../../../components/core/VeeValidateForm/Input.vue";
import TextArea from "../../../components/core/VeeValidateForm/TextArea.vue";
import * as _ from "lodash";

const defaultVariable = {
  name: "credit_card_payment_clients",
  value: [],
};

const defaultForm = {
  index: undefined,
  percentage: 0,
  url: "",
};

export default {
  name: "CreditCardPaymentClientsForm",
  components: {
    ValidationObserver,
    StandardTable,
    Input,
    TextArea,
  },
  data() {
    return {
      labels: labels.systemConfig.tabs.creditCardPaymentClients,
      exception: labels.exception,
      loading: false,
      submitting: false,
      variable: _.cloneDeep(defaultVariable),
      open: false,
      form: _.cloneDeep(defaultForm),
    };
  },
  async created() {
    this.resetForm();
    this.handleFetchCreditCardPaymentClients();
  },
  computed: {
    columns() {
      return [
        {
          title: this.labels.table.columns.percentage,
          dataIndex: "percentage",
          width: 40,
        },
        {
          title: this.labels.table.columns.url,
          dataIndex: "url",
          width: 240,
        },
        {
          title: this.labels.table.columns.actions.title,
          dataIndex: "actions",
          align: "center",
          width: 60,
          customRender: (_, record, index) => {
            return (
              <span>
                <a-button
                  type="link"
                  icon="edit"
                  size="small"
                  onClick={() => this.handleEdit({ ...record, index })}
                >
                  {this.labels.table.columns.actions.edit}
                </a-button>
                <a-button
                  type="link"
                  icon="delete"
                  size="small"
                  onClick={() => this.handleDelete({ ...record, index })}
                  style="color: red"
                >
                  {this.labels.table.columns.actions.delete}
                </a-button>
              </span>
            );
          },
        },
      ];
    },
  },
  methods: {
    ...mapActions("systemConfig", [
      "fetchCreditCardPaymentClients",
      "saveCraueConfig",
    ]),
    async handleFetchCreditCardPaymentClients() {
      try {
        this.loading = true;
        const { data } = await this.fetchCreditCardPaymentClients();
        Object.assign(this.variable, {
          ...data,
          value: JSON.parse(data.value),
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async handleSubmit() {
      const { variable, saveCraueConfig } = this;
      if (this.submitting) return;
      try {
        this.submitting = true;
        const { message } = await saveCraueConfig({
          variables: [
            {
              ...variable,
              value: JSON.stringify(variable.value),
            },
          ],
        });
        await this.handleFetchCreditCardPaymentClients();
        this.$message.success(message);
      } catch (error) {
        this.$message.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error.message
        );
        this.$log.error(error);
      } finally {
        this.submitting = false;
      }
    },
    resetForm() {
      this.form = _.cloneDeep(defaultForm);
      if (this.$refs.formObserver) this.$refs.formObserver.reset();
    },
    async handleAdd() {
      try {
        const isValid = await this.$refs.formObserver.validate();
        if (!isValid) return;

        const { form, variable } = this;
        const { percentage, url, index = null } = form;
        const { value } = variable;
        if (index !== null) {
          value.splice(index, 1, {
            percentage,
            url,
          });
        } else {
          value.push({
            percentage,
            url,
          });
        }
        this.open = false;
        this.resetForm();
      } catch (error) {
        console.error(error);
      }
    },
    handleDelete(record) {
      const { value } = this.variable;
      value.splice(record.index, 1);
    },
    handleCancel() {
      this.open = false;
      this.resetForm();
    },
    handleEdit(record) {
      this.form = _.cloneDeep(record);
      this.open = true;
    },
  },
  beforeDestroy() {
    this.form = _.cloneDeep(defaultForm);
    this.variable = _.cloneDeep(defaultVariable);
  },
};
</script>
<style lang="scss">
@import "../../../assets/scss/variable";
.credit-card-payment-clients-form {
  .btn-submit {
    text-align: center !important;
  }
  .border-bottom {
    border-bottom: 1.5px solid color(border-color);
    &:last-child {
      border-bottom: none;
    }
  }
}
</style>
