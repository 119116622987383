<template>
  <div class="system-config-page">
    <a-row type="flex" justify="space-between">
      <a-col :lg="10" :md="10" :xs="24">
        <h2 class="system-config-title">
          <font-awesome-icon
            class="anticon system-config-icon"
            :icon="['fas', 'cogs']"
          />
          <span>{{ labels.title }}</span>
        </h2>
      </a-col>
    </a-row>
    <a-row type="flex">
      <a-col :span="24">
        <a-tabs v-model="activeKey" type="card" class="system-config-tabs">
          <a-tab-pane key="smtp-config">
            <template #tab>
              <span>
                <img :src="IconEmailCogs" alt="icon" class="smtp-config-icon" />
                {{ labels.tabs.smtpConfig.title }}
              </span>
            </template>
            <SmtpForm v-if="activeKey === 'smtp-config'" />
          </a-tab-pane>
          <a-tab-pane key="global-variable">
            <template #tab>
              <span>
                <font-awesome-icon
                  class="anticon global-variable-icon"
                  :icon="['fas', 'cog']"
                />
                {{ labels.tabs.craueConfig.title }}
              </span>
            </template>
            <CraueConfigForm v-if="activeKey === 'global-variable'" />
          </a-tab-pane>
          <a-tab-pane key="policies-terms">
            <template #tab>
              <span>
                <font-awesome-icon
                  class="anticon policies-terms-icon"
                  :icon="['fas', 'file-shield']"
                />
                {{ labels.tabs.termAndPolicy.title }}
              </span>
            </template>
            <PolicyTermForm v-if="activeKey === 'policies-terms'" />
          </a-tab-pane>
          <a-tab-pane key="welcome-page">
            <template #tab>
              <span>
                <font-awesome-icon
                  class="anticon welcome-page-icon"
                  :icon="['fas', 'person-circle-question']"
                />
                {{ labels.tabs.welcomePage.title }}
              </span>
            </template>
            <welcome-page-form v-if="activeKey === 'welcome-page'" />
          </a-tab-pane>
          <a-tab-pane key="creditcard-payment-clients">
            <template #tab>
              <span>
                <font-awesome-icon
                  class="anticon creditcard-payment-clients-icon"
                  :icon="['far', 'credit-card']"
                />
                {{ labels.tabs.creditCardPaymentClients.title }}
              </span>
            </template>
            <CreditCardPaymentClientForm
              v-if="activeKey === 'creditcard-payment-clients'"
            />
          </a-tab-pane>
          <a-tab-pane key="deposit-accounts">
            <template #tab>
              <span>
                <font-awesome-icon
                  class="anticon deposit-accounts-icon"
                  :icon="['fas', 'money-bill-transfer']"
                />
                {{ labels.tabs.depositAccounts.title }}
              </span>
            </template>
            <DepositAccountsForm v-if="activeKey === 'deposit-accounts'" />
          </a-tab-pane>
        </a-tabs>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import labels from "@/utils/labels";
import { adminPageTitle } from "../../../utils/utils";
import IconEmailCogs from "@/assets/images/admin/email-cogs.svg";
import SmtpForm from "./SmtpForm.vue";
import CraueConfigForm from "./CraueConfigForm.vue";
import PolicyTermForm from "./PolicyTermForm.vue";
import WelcomePageForm from "./WelcomePageForm.vue";
import CreditCardPaymentClientForm from "./CreditCardPaymentClientForm.vue";
import DepositAccountsForm from "./DepositAccountsForm.vue";
export default {
  components: {
    SmtpForm,
    CraueConfigForm,
    PolicyTermForm,
    CreditCardPaymentClientForm,
    WelcomePageForm,
    DepositAccountsForm,
  },
  name: "SystemConfig",
  metaInfo: {
    title: adminPageTitle(labels.systemConfig.title),
  },
  data() {
    return {
      labels: labels.systemConfig,
      activeKey: "smtp-config",
      IconEmailCogs: IconEmailCogs,
    };
  },
  methods: {},
  computed: {},
};
</script>
<style lang="scss">
@import "../../../assets/scss/variable";
.system-config-page {
  .system-config {
    &-title {
      margin-bottom: 10px;
      .system-config-icon {
        margin-right: 10px;
      }
    }
  }
  .system-config-tabs {
    .smtp-config-icon {
      margin-right: 10px;
      width: 21px;
    }
    .deposit-accounts-icon,
    .policies-terms-icon,
    .welcome-page-icon,
    .global-variable-icon {
      margin-right: 10px;
    }
  }
}
</style>
